import React from 'react';
import './LaunchPage.css'; // Import the CSS file for styling
import { ReactComponent as Logo } from '../assets/logo.svg'; // Import your logo image
import backgroundVideo from '../assets/loading.mp4'; // Import your background video
import NotifyButton from './NotifyButton';
const LaunchPage = () => {
    return (
        <div className="launch-page">
            <video autoPlay muted loop className="background-video">
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="content">
                <Logo className="logo" />
                <h1 className="title">Saha Nexus Corporation</h1>
            </div>
            <div>
                <NotifyButton />
            </div>
        </div>
    );
};

export default LaunchPage;
