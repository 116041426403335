import logo from './logo.svg';
import './App.css';
import LaunchPage from './components/LaunchPage';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LaunchPage />


      </header>
    </div>
  );
}

export default App;
