import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import './NotifyButton.css';
const NotifyButton = () => {
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);

    const handleOk = async () => {
        try {
            const response = await fetch('https://formspree.io/f/mgegkjdv', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                alert('Email submitted successfully!');
                setEmail('');
                setVisible(false);
            } else {
                throw new Error('Failed to submit email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit email');
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div>
            <Button
                type="primary"
                onClick={() => setVisible(true)}
                style={{ backgroundColor: 'white', color: 'black', marginTop: '36%', border: '2px solid white', fontWeight: 'bold' }}
                className="white-button"
            >
                Notify Me
            </Button>
            <Modal
                title="Notify Me"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Send"
            >
                <Input
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleInputChange}
                />
            </Modal>
        </div>
    );
};

export default NotifyButton;
